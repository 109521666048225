import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from "theme-ui";
import { TypeformLink } from "@swizec/gatsby-theme-course-platform";
export const title = "Thanks ❤️";
export const description = "Thanks for supporting the Serverless Handbook";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "thank-you-for-supporting-serverless-handbook-️"
    }}>{`Thank you for supporting Serverless Handbook ❤️`}</h1>
    <p><lite-youtube videoid="cgmjsd9biyE" autoload></lite-youtube></p>
    <p>{`Hey thanks again for supporting Serverless Handbook. I hope it helps you on your journey.`}</p>
    <p>{`Here's what happens next 👇`}</p>
    <p>{`I just saved a cookie in your browser. Now it knows that you have full access to the content. No more opt-in buttons, hidden content, or anything like that. Get started with the menu on the left, or click the Back button to see the page you were looking at before.`}</p>
    <p>{`My robots created an account for you and sent an email with further instructions. `}<strong parentName="p">{`You'll have to set a password.`}</strong></p>
    <p>{`Over the next few weeks, you'll get a chapter in your inbox every 3 days or so. Because buying books and reading books are different hobbies :)`}</p>
    <p>{`Wanna help improve Serverless Handbook? Answer 2 quick questions`}</p>
    <TypeformLink url="https://swizecteller.typeform.com/to/AJgFM5" mdxType="TypeformLink" />
    <p>{`Happy learning,`}<br />{`
~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      